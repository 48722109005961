import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import 'styles/pages/allListsPage.scss'
import { listController } from 'controllers'
import { listInterface } from 'interfaces'
import { MiniListPreview } from 'components/list'
import { listHelper } from 'helpers'
import { useSnackbar } from 'notistack'
import { Button } from 'components/generic/Button'

function AllListsPage({ isLoggedIn }: { isLoggedIn: boolean }) {
  const [search, setSearch] = useState('')
  const [lists, setLists] = useState<listInterface.IListExtended[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn, navigate])

  async function getAllLists() {
    let parsedListsFetch: listInterface.IListExtended[]

    try {
      const listsFetch = await listController.getAllLists({})
      parsedListsFetch = await listHelper.parseListUsers({ lists: listsFetch.items })
      setLists(parsedListsFetch)
    } catch (error: any) {
      console.log(error)
    }

    return setIsLoading(false)
  }

  async function findLists() {
    try {
      const listsFetch = await listController.getListsByNamePaginated(search)
      return setLists(listsFetch.items)
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }
  }

  async function getListsPaginated() {
    try {
      const listsFetch =
        search.length > 2
          ? await listController.getListsByNamePaginated(search, page + 1)
          : await listController.getAllLists({ page: page + 1 })
      const parsedListsFetch = await listHelper.parseListUsers({ lists: listsFetch.items })
      if (lists) setLists([...lists, ...parsedListsFetch])
      setPage(page + 1)
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    setPage(1)
    if (search.length > 2) findLists()
    else if (search.length === 0) getAllLists()
  }, [search])

  useEffect(() => {
    getAllLists()
  }, [])

  return isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <div className='allListsPage'>
      <h1 className='title'>All Lists</h1>
      <input className='searchField' value={search} onChange={(v) => setSearch(v.target.value)} />
      <div className='listBox'>
        {lists?.map((list) => (
          <div key={list.uuid} className='listWrapper'>
            <MiniListPreview reloadLists={() => getAllLists()} listData={list} />
          </div>
        ))}
      </div>
      <Button onClick={() => getListsPaginated()}>Load More</Button>
    </div>
  )
}

export default AllListsPage
