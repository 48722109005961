export const categoryOptions = [
  { value: 'Books', label: 'Books' },
  { value: 'Business', label: 'Business' },
  { value: 'Education', label: 'Education' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Food & Drink', label: 'Food & Drink' },
  { value: 'Games', label: 'Games' },
  { value: 'Health & Fitness', label: 'Health & Fitness' },
  { value: 'Lifestyle', label: 'Lifestyle' },
  { value: 'Magazines & Newspapers', label: 'Magazines & Newspapers' },
  { value: 'Medical', label: 'Medical' },
  { value: 'Music', label: 'Music' },
  { value: 'Navigation', label: 'Navigation' },
  { value: 'News', label: 'News' },
  { value: 'Photo & Video', label: 'Photo & Video' },
  { value: 'Productivity', label: 'Productivity' },
  { value: 'Reference', label: 'Reference' },
  { value: 'Shopping', label: 'Shopping' },
  { value: 'Social Networking', label: 'Social Networking' },
  { value: 'Sports', label: 'Sports' },
  { value: 'Stickers', label: 'Stickers' },
  { value: 'Travel', label: 'Travel' },
  { value: 'Utilities', label: 'Utilities' },
  { value: 'Weather', label: 'Weather' },
]

export const announcementOptions = [
  { value: 'error', label: 'Error' },
  { value: 'warning', label: 'Warning' },
]

export const linksOptions = [{ value: 'twitter', label: 'Twitter' }]

export const currencyOptions = [
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'GBP', label: 'GBP' },
  { value: 'CAD', label: 'CAD' },
]

export const emailFrequencies = [
  { value: '1', label: 'Weekly' },
  { value: '2', label: 'Bi-Weekly' },
  { value: '3', label: 'Monthly' },
]
