import React from 'react'
import 'styles/components/list/publicBadge.scss'

function PublicBadge() {
  return (
    <div className='publicBadge'>
      <span className='text'>public</span>
    </div>
  )
}

export default PublicBadge
