import { Status, IReport, IApp } from './appInterface'
import { IUserPublic } from './userInterface'

export interface IAppSuggestionExtended {
  uuid: string
  platforms: IPlatforms
  title: string
  description: string
  categories: string[]
  tags?: string[]
  url: string
  status?: Status
  reports?: IReport[]
  imageUrl: string
  screenshots?: string[]
  releaseDate?: string
  sellerName: string
  customAttributes?: ICAttributes[]
  app: IApp
  user: IUserPublic
}

export enum Platform {
  itunes = 'itunes',
  android = 'android',
  windows = 'windows',
  mac = 'mac',
  linux = 'linux',
  web = 'web',
}

export interface IPlatform {
  id?: string
  url?: string
  type?: Platform
  requestId?: string
  sellerUrl?: string
  sellerName?: string
  icon?: string
  rating?: number
  ratingCount?: number
  approved?: boolean
  price?: number
  version?: string
}

export interface IPlatforms {
  itunes?: IPlatform
  android?: IPlatform
  windows?: IPlatform
  mac?: IPlatform
  linux?: IPlatform
  web?: IPlatform
  requests?: IPlatform[]
}

export interface ICAttributes {
  id?: string
  name?: string
  value?: string
}

export interface INewAppSuggestion {
  platforms?: IPlatforms
  title?: string
  description?: string
  categories?: string[]
  tags?: string[]
  imageUrl?: string
  screenshots?: string[]
  releaseDate?: string
  sellerName?: string
  customAttributes?: ICAttributes[]
  url?: string
}
