import React from 'react'
import 'styles/components/search/suggestions.scss'
import { appInterface } from 'interfaces'
import { AppSearchComponent, NoSearchResults } from '.'

function Suggestions({ apps }: { apps?: appInterface.IItunesParsedApp[] | appInterface.IApp[] }) {
  return (
    <div className={`suggestions ${apps && 'active'}`}>
      {apps && apps.length > 0 ? (
        apps.map((app) => <AppSearchComponent key={app.uuid} clickable appData={app} />)
      ) : (
        <NoSearchResults />
      )}
    </div>
  )
}

export default Suggestions
