/* eslint-disable no-constant-condition */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { BsXLg } from 'react-icons/bs'
import { appController } from 'controllers'
import { appInterface } from 'interfaces'

import 'styles/pages/pendingPlatforms.scss'

function PendingPlatforms({ isLoggedIn }: { isLoggedIn: boolean }) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [search, setSearch] = useState<string>('')
  const [apps, setApps] = useState<appInterface.IApp[]>([])
  const navigate = useNavigate()

  async function getPendingApps() {
    let i = 0
    while (true) {
      const newPage: { apps: appInterface.IApp[]; count: number } = await appController.getAllApps({
        page: i,
        filter: { 'platforms.requests.0': { $exists: true } },
      })
      if (!newPage?.apps?.length) return setIsLoading(false)
      setApps((oldApps) => oldApps.concat(newPage.apps.map((s) => ({ ...s, uuid: s.uuid }))))
      i += 1
    }
  }

  const checkSearch = (title: string) => (search ? title.toLocaleLowerCase().match(search) : true)

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn])

  useEffect(() => {
    getPendingApps()
  }, [])

  return isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <div className='container'>
      <h1>Pending Platforms</h1>
      <input
        className='searchField'
        onChange={(e) => {
          setSearch(e.target.value.toLocaleLowerCase())
        }}
      />
      <div className='appList'>
        {/*  && app.platforms.requests?.length */}
        {apps &&
          apps.length > 0 &&
          apps.map((app) =>
            checkSearch(app.title) ? (
              <div key={app.uuid}>
                <div className='appComponent'>
                  <Link className='appUrl' to={`/apps/pending-platforms/${app.uuid}`}>
                    <img className='appImage' src={app.imageUrl} alt={app.title} />
                  </Link>
                  <div className='textBox'>
                    <Link className='appUrl' to={`/apps/pending-platforms/${app.uuid}`}>
                      <p className='title'>{app.title}</p>
                    </Link>
                    <p className='description'>{app.description}</p>
                  </div>
                </div>
              </div>
            ) : null,
          )}
      </div>
    </div>
  )
}

export default PendingPlatforms
