import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { BsPlusLg, BsXLg } from 'react-icons/bs'
import { useSnackbar } from 'notistack'
import 'styles/pages/listPage.scss'
import { appController, listController } from 'controllers'
import { appInterface, listInterface } from 'interfaces'
import { LoadingSpinner } from 'components/generic'
import { AppList } from 'components/apps'
import { SavableSuggestions } from 'components/search'

function ListPage() {
  const params = useParams()
  const navigate = useNavigate()
  const uuid: string = params.uuid || ''
  const [list, setList] = useState<listInterface.IList>()
  const [apps, setApps] = useState<appInterface.IApp[]>()
  const [showAppField, setShowAppField] = useState<boolean>(false)
  const [appSearch, setAppSearch] = useState<string>('')
  const [suggestions, setSuggestions] = useState<appInterface.IApp[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { enqueueSnackbar } = useSnackbar()

  async function getListById() {
    let listFetch: listInterface.IList
    let appsFetch: appInterface.IApp[]
    try {
      listFetch = await listController.getListById(uuid)
      appsFetch = await appController.getManyAppsById(listFetch.apps)
      setApps(appsFetch)
      setList(listFetch)
      return setIsLoading(false)
    } catch (error: any) {
      return navigate('/home')
    }
  }

  async function saveApp(appData: appInterface.IApp) {
    try {
      if (list) await listController.addAppToList({ uuid: list.uuid, appData })
      await getListById()
      return enqueueSnackbar('App Saved', { variant: 'success' })
    } catch (error: any) {
      return enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  async function deleteApp(appData: appInterface.IApp) {
    try {
      if (list) await listController.deleteAppFromList(list.uuid, appData.uuid)
      await getListById()
      return enqueueSnackbar(`${appData.title} has been deleted`, { variant: 'success' })
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    getListById()
  }, [])

  async function searchLocalApps() {
    let suggestionSearch: appInterface.IItunesParsedApp[]
    try {
      suggestionSearch = await appController.getManyAppsByName({ name: appSearch })
      return setSuggestions(suggestionSearch)
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    if (appSearch.length > 2) searchLocalApps()
    else setSuggestions(undefined)
  }, [appSearch])

  return !isLoading && list && apps ? (
    <div className='listPage'>
      <h1 className='title'>{list.name}</h1>
      <p className='description'>{list.description}</p>
      <span className='counter'>{apps.length} Apps</span>
      <AppList apps={apps} deleteApp={(appData: appInterface.IApp) => deleteApp(appData)} />
      <div className='searchBox'>
        {showAppField && (
          <input
            className='searchField'
            value={appSearch}
            onChange={(v) => setAppSearch(v.target.value)}
          />
        )}
        {showAppField ? (
          <BsXLg
            className='addIcon close'
            onClick={() => {
              setAppSearch('')
              setShowAppField(false)
            }}
          />
        ) : (
          <BsPlusLg
            className='addIcon'
            onClick={() => {
              setShowAppField(true)
            }}
          />
        )}
      </div>
      <SavableSuggestions
        apps={suggestions}
        saveApp={(appData: appInterface.IApp) => saveApp(appData)}
      />
    </div>
  ) : (
    <LoadingSpinner />
  )
}

export default ListPage
