import { IApp } from './appInterface'
import { LabelValue } from './generic'

export enum SubscriptionStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
}

export enum SubscriptionPlan {
  Free = 'Free',
  Paid = 'Paid',
}

export enum SubscriptionInterval {
  Month = 'month',
  Year = 'year',
}

export interface ISubscription {
  uuid: string
  app: IApp
  appId: string
  status: SubscriptionStatus
  plan: SubscriptionPlan
  cost?: number
  currency: LabelValue
  interval: SubscriptionInterval
  note: string
}

export interface INewSubscription {
  appId: string
  plan: SubscriptionPlan
  cost?: number
  currency: string
  interval: 'month' | 'year'
  note: string
}
