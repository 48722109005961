import React from 'react'

function NoSearchResults() {
  return (
    <div className='appComponent'>
      <div className='textBox'>
        <p className='title'>No Results Found</p>
      </div>
    </div>
  )
}

export default NoSearchResults
