/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { listInterface } from 'interfaces'
import { InputField, CheckboxField, Modal } from 'components/generic'
import 'styles/components/list/newListModal.scss'
import { listController } from 'controllers'

interface NewListModalProps {
  hideModal: () => void
  updateLists: () => void
}

function NewListModal({ hideModal, updateLists }: NewListModalProps) {
  const { enqueueSnackbar } = useSnackbar()
  const [listData, setListData] = useState<listInterface.INewList>({ isPublic: false })

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setListData({
      ...listData,
      [e.target.name]: e.target.value,
    })
  }

  async function createList() {
    try {
      await listController.createList(listData)
      enqueueSnackbar('List created', { variant: 'success' })
      hideModal()
      updateLists()
      return
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  return (
    <Modal hideModal={() => hideModal()}>
      <div className='newListModal'>
        <h1 className='title'>New List</h1>
        <div className='inputWrapper'>
          <InputField
            name='name'
            label='List Name'
            value={listData?.name}
            handleChange={(e) => handleChange(e)}
          />
          <InputField
            name='description'
            label='Description'
            value={listData?.description}
            handleChange={(e) => handleChange(e)}
          />
          <CheckboxField
            name='isPublic'
            label='Public'
            isChecked={listData?.isPublic}
            handleChange={() => setListData({ ...listData, isPublic: !listData?.isPublic })}
          />
        </div>
        <button type='button' onClick={createList} className='cta'>
          Submit
        </button>
      </div>
    </Modal>
  )
}

export default NewListModal
