import { appInterface } from 'interfaces'

export function filterSelectorArray(selectorValues?: Array<any>) {
  const filterSelectorPusher: Array<string> = []
  selectorValues?.map((selector) => filterSelectorPusher.push(selector.value))
  return filterSelectorPusher
}

export function checkAddedApps(
  localApps: appInterface.IApp[] | appInterface.IItunesParsedApp[],
  searchApps: appInterface.IApp[] | appInterface.IItunesParsedApp[],
) {
  const filteredApps: appInterface.IApp[] | appInterface.IItunesParsedApp[] = searchApps
  let addedAppIndex: number
  localApps.map((localApp) => {
    addedAppIndex = searchApps.findIndex((app) => app.uuid === localApp.uuid)
    if (addedAppIndex >= 0) filteredApps[addedAppIndex].inList = true
    return filteredApps
  })
  return filteredApps
}

export const checkAddedAppsByIds = (localApps: string[], externalApps: appInterface.IApp[]) => {
  return externalApps.filter((app) => !localApps.includes(app.uuid))
}
