import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ListTable } from 'components/list'
import { listController, userController } from 'controllers'
import { listInterface, userInterface } from 'interfaces'
import { useSnackbar } from 'notistack'
import 'styles/pages/userPage.scss'
import { Done as DoneIcon, Close as CloseIcon } from '@mui/icons-material'
import UserTable from './UserTable'
import { getReadableDate } from 'utils/date'

function UserPage({ isLoggedIn }: { isLoggedIn: boolean }) {
  const { uuid } = useParams()
  const [user, setUser] = useState<userInterface.IUser>()
  const [followedUsers, setFollowedUsers] = useState<userInterface.IUser[]>([])
  const [followedLists, setFollowedLists] = useState<listInterface.IList[]>([])
  const [lists, setLists] = useState<listInterface.IList[]>([])
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn, navigate])

  async function getUserData() {
    try {
      if (uuid) {
        const [fetchedUser] = await userController.getUsersByIds({ uuidList: [uuid] })
        const fetchedFollowedUsers = await userController.getUsersByIds({
          uuidList: fetchedUser.followedUsers?.map((user) => user.id) || [],
        })
        const fetchedLists = await listController.getListsByUserId(uuid)
        const fetchedFollowedLists = await listController.getListsByIds({
          uuidList: fetchedUser.followedLists?.map((list) => list.id) || [],
        })
        setUser(fetchedUser)
        setLists(fetchedLists)
        setFollowedUsers(fetchedFollowedUsers)
        setFollowedLists(fetchedFollowedLists)
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  const activateUser = async () => {
    try {
      await userController.activateUser({
        uuid: user?.uuid,
      })
      getUserData()
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    getUserData()
  }, [uuid])

  const emailVerified = user?.config?.onboarding?.emailVerified
  const isOnWaitlist = user?.config?.waitlist?.isOnWaitlist
  const conversionDate = getReadableDate(user?.config?.waitlist?.converted)
  const registrationDate = getReadableDate(user?.timestamp)
  const lastLoginDate = getReadableDate(user?.lastLogin)

  return user ? (
    <div className='userPage'>
      <h4>Actions</h4>
      {isOnWaitlist && (
        <button type='button' onClick={() => activateUser()} className='cta'>
          Activate From Waitlist
        </button>
      )}
      <h4>Basic Info</h4>
      <div className='box'>
        <p className='userInfo'>
          <span className='label'>Name:</span> <span className='value'>{user.name}</span>
        </p>
        <p className='userInfo'>
          <span className='label'>UUID:</span> <span className='value'>{user.uuid}</span>
        </p>
        <p className='userInfo'>
          <span className='label'>Email:</span> <span className='value'>{user.email}</span>
        </p>
        <p className='userInfo'>
          <span className='label'>Username:</span> <span className='value'>{user.username}</span>
        </p>
        {registrationDate && (
          <p className='userInfo'>
            <span className='label'>Registered:</span>{' '}
            <span className='value'>{registrationDate}</span>
          </p>
        )}
        {lastLoginDate && (
          <p className='userInfo'>
            <span className='label'>Last Login:</span>{' '}
            <span className='value'>{lastLoginDate}</span>
          </p>
        )}
        <p className='userInfo'>
          <span className='label'>Email Verified:</span>{' '}
          <span className='value'>{emailVerified ? <DoneIcon /> : <CloseIcon />}</span>
        </p>
        <p className='userInfo'>
          <span className='label'>On Waitlist:</span>{' '}
          <span className='value'>{isOnWaitlist ? <DoneIcon /> : <CloseIcon />}</span>
        </p>
        {conversionDate && !isOnWaitlist && (
          <p className='userInfo'>
            <span className='label'>Activation Date:</span>{' '}
            <span className='value'>{conversionDate}</span>
          </p>
        )}
      </div>
      <h4>Owned Lists</h4>
      <ListTable reloadLists={() => lists} lists={lists} />
      <h4>Followed Lists</h4>
      <ListTable reloadLists={() => lists} lists={followedLists} />
      <h4>Followed Users</h4>
      <UserTable miniList users={followedUsers} />
    </div>
  ) : (
    <h1>Loading...</h1>
  )
}

export default UserPage
