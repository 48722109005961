import React, { useState } from 'react'
import { Button } from '@mui/material'
import { TextField } from '@material-ui/core'
import { subscriptionsController } from 'controllers'
import { AppSearch } from 'components/apps/AppSearch'
import { useSnackbar } from 'notistack'
import { IApp } from 'interfaces/appInterface'
import { InputField, SelectorField } from 'components/generic'
import { LabelValue } from 'interfaces/generic'
import { AppList } from 'components/apps'
import styles from './EditSubscription.module.scss'
import { currencyOptions } from 'utils/appConsts'
import {
  INewSubscription,
  SubscriptionInterval,
  SubscriptionPlan,
} from 'interfaces/subscriptionsInterface'

interface AddSubscriptionProps {
  reload?: () => void
  setMode: (mode: boolean) => void
}

export const AddSubscription = ({ reload, setMode }: AddSubscriptionProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [selectedApp, setSelectedApp] = useState<IApp | undefined>(undefined)
  const [subscriptionData, setSubscriptionData] = useState<INewSubscription>({
    appId: '',
    cost: undefined,
    currency: 'USD',
    interval: SubscriptionInterval.Month,
    plan: SubscriptionPlan.Paid,
    note: '',
  })

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    setSubscriptionData((s) => ({ ...s, [e.target.name]: e.target.value }))
  }

  const addSubscription = async () => {
    try {
      await subscriptionsController.createSubscription({ ...subscriptionData })

      if (reload) reload()
      enqueueSnackbar('Subscription added', { variant: 'success' })
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
    setMode(false)
  }

  const handleSetSubscriptionApp = (app?: IApp) => {
    setSubscriptionData((s) => ({ ...s, appId: app?.uuid || '' }))
    setSelectedApp(app)
  }

  return (
    <div className={styles.editSubscription}>
      {selectedApp ? (
        <AppList
          deleteApp={() => handleSetSubscriptionApp(undefined)}
          apps={selectedApp ? [selectedApp] : []}
        />
      ) : (
        <AppSearch existingAppIds={[]} saveApp={handleSetSubscriptionApp} />
      )}
      {subscriptionData.plan === SubscriptionPlan.Paid && (
        <div className={styles.priceBox}>
          <InputField
            name='cost'
            label='Cost'
            value={subscriptionData.cost}
            handleChange={handleChange}
          />
          <SelectorField
            name='currency'
            label='Currency'
            options={currencyOptions}
            value={{ label: subscriptionData.currency, value: subscriptionData.currency }}
            handleChange={(e: LabelValue) =>
              setSubscriptionData((s) => ({ ...s, currency: e.value }))
            }
          />
        </div>
      )}
      <TextField
        label='Subscription Note'
        placeholder='Enter a note (up to 100 characters)'
        variant='outlined'
        fullWidth
        value={subscriptionData.note}
        onChange={(e) => setSubscriptionData((s) => ({ ...s, note: e.target.value }))}
        inputProps={{
          maxLength: 100,
        }}
        InputLabelProps={{
          style: { color: 'white' },
        }}
        InputProps={{
          style: { color: 'white' },
        }}
        style={{ marginBottom: '5px' }}
      />
      <Button onClick={addSubscription} variant='contained'>
        Submit
      </Button>
    </div>
  )
}
