import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import 'styles/pages/loginPage.scss'
import { InputField, PasswordInputField } from 'components/generic'
import { adminController } from 'controllers'
import { userInterface } from 'interfaces'
import { errorHelper } from 'helpers'

function LoginPage({
  isLoggedIn,
  setIsLoggedIn,
}: {
  isLoggedIn: boolean
  setIsLoggedIn: React.Dispatch<React.SetStateAction<any>>
}) {
  const navigate = useNavigate()
  const [loginData, setLoginData] = useState<userInterface.IUserLogin>()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isLoggedIn) navigate('/')
  }, [isLoggedIn])

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target
    setLoginData({
      ...loginData,
      [e.target.name]: value,
    })
  }

  async function handleLogin() {
    try {
      if (loginData) await adminController.login(loginData)
      else throw new errorHelper.CodeError('Login data is missing', 401)
      enqueueSnackbar('Logged in', { variant: 'success' })
      return setIsLoggedIn(true)
    } catch (error: any) {
      return enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  return (
    <div className='loginPage'>
      <h1 className='title'>Login</h1>
      <div className='inputWrapper'>
        <InputField
          name='email'
          label='Email'
          value={loginData?.email}
          handleChange={(e) => handleChange(e)}
        />
        <PasswordInputField
          name='password'
          label='Password'
          value={loginData?.password}
          handleChange={(e) => handleChange(e)}
        />
      </div>
      {/* <p className="register">
        Do not have an account yet?
        {' '}
        <Link className="link" to="/register">Register</Link>
      </p> */}
      <button type='button' onClick={handleLogin} className='cta'>
        Login
      </button>
    </div>
  )
}

export default LoginPage
