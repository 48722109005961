/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { appController } from 'controllers'
import { appInterface } from 'interfaces'
import { AppPlatforms } from 'components/apps'
import 'styles/pages/pendingPlatformPage.scss'

function PendingPlatformsChangePage({ isLoggedIn }: { isLoggedIn: boolean }) {
  const [app, setApp] = useState<appInterface.IApp>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const uuid: string = useParams().uuid!

  async function getAppById() {
    try {
      const appSearch = await appController.getAppById(uuid)
      setApp(appSearch)
      setIsLoading(false)
    } catch (error: any) {
      navigate('/')
    }
  }

  useEffect(() => {
    getAppById()
  }, [])

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn])

  if (isLoading) return <h1>Loading...</h1>
  if (!app) return <div />
  return (
    <div className='pendingPlatform'>
      <div className='imgContainer'>
        <img src={app.imageUrl} alt={app?.title} />
      </div>
      <h2 className='text'>{app.title}</h2>
      <AppPlatforms
        refreshApp={getAppById}
        appId={app.uuid}
        platformRequests={app.platforms.requests}
      />
    </div>
  )
}

export default PendingPlatformsChangePage
