import React from 'react'
import 'styles/components/attributes/AttributeList.scss'
import { BsXLg } from 'react-icons/bs'
import { attributeInterface } from 'interfaces'

function AttributeList({
  attributes,
  deleteAttribute,
}: {
  attributes?: attributeInterface.IAttribute[]
  deleteAttribute?: (attributeId: string) => void
}) {
  return (
    <div className='attributeList'>
      {attributes &&
        attributes.length > 0 &&
        attributes.map((attribute) => (
          <div key={attribute.uuid}>
            <div className='attributeComponent'>
              <p className='name'>{attribute.name}</p>
              {deleteAttribute && (
                <BsXLg
                  onClick={() => {
                    deleteAttribute(attribute.uuid)
                  }}
                  className='attributeDeleteIcon'
                />
              )}
            </div>
          </div>
        ))}
    </div>
  )
}

export default AttributeList
