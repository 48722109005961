import React from 'react'
import { BsPlusLg, BsCheckLg } from 'react-icons/bs'
import { IList } from 'interfaces/listInterface'

interface ListSearchSaveComponentProps {
  listData: IList
  saveList: (listId: string) => void
}

export const ListSearchSaveComponent = ({ listData, saveList }: ListSearchSaveComponentProps) => {
  return (
    <div
      className='appComponent'
      role='button'
      onKeyDown={() => {
        saveList(listData.uuid)
      }}
      tabIndex={0}
      onClick={() => {
        if (!listData?.isFeatured) saveList(listData.uuid)
      }}
      key={listData.uuid}
    >
      <div className='dataBox'>
        <div className='textBox'>
          <p className='title'>{listData.name}</p>
        </div>
      </div>
      {listData.isFeatured ? (
        <BsCheckLg className='saveIcon addedIcon' />
      ) : (
        <BsPlusLg className='saveIcon' />
      )}
    </div>
  )
}
