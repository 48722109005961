import React from 'react'
import { BsXLg } from 'react-icons/bs'
import { FiEdit } from 'react-icons/fi'
import { MdOutlineDelete } from 'react-icons/md'
import 'styles/components/generic/modal.scss'

interface ModalProps {
  children: JSX.Element | JSX.Element[]
  hideModal: () => void
  editModal?: () => void
  deleteModal?: () => void
}

function Modal({ children, hideModal, editModal, deleteModal }: ModalProps) {
  return (
    <div className='modal'>
      <div className='innerModal'>
        {editModal && <FiEdit className='editIcon' onClick={() => editModal()} />}
        {deleteModal && <MdOutlineDelete className='deleteIcon' onClick={() => deleteModal()} />}
        <BsXLg className='closeIcon' onClick={() => hideModal()} />
        {children}
      </div>
    </div>
  )
}

export default Modal
