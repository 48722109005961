import React from 'react'
import styles from './SubscriptionsHeader.module.scss'
import { ISubscription } from 'interfaces/subscriptionsInterface'

interface SubscriptionsHeaderProps {
  subscriptions: ISubscription[]
}

export const SubscriptionsHeader = ({ subscriptions }: SubscriptionsHeaderProps) => {
  return (
    <div className={styles.subscriptionsHeader}>
      <div className={styles.total}>
        <span className={styles.totalCurrency}>Suggested Subscriptions</span>
        <span className={styles.spacer}>:</span>
        <span className={styles.totalValue}>{subscriptions.length || 0}</span>
      </div>
    </div>
  )
}
