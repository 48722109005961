import { userController } from 'controllers'
import { listInterface } from 'interfaces'

export async function parseListUsers({ lists }: { lists: listInterface.IList[] }) {
  const uuidList: string[] = lists.map((list) => list.userId)
  const users = await userController.getUsersByIds({ uuidList })
  const parsedLists: listInterface.IListExtended[] = lists.map((list) => ({
    ...list,
    username:
      users.find(({ uuid }: { uuid: string }) => uuid === list.userId)?.username || 'Deleted User',
  }))
  return parsedLists
}
