/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import 'styles/components/list/listTable.scss'
import { listInterface } from 'interfaces'
import { ListPreview } from 'components/list'

interface ListTableProps {
  lists?: listInterface.IList[]
  reloadLists: () => void
}

function ListTable({ lists, reloadLists }: ListTableProps) {
  return (
    <div className='listTable'>
      {lists && lists?.length > 0 ? (
        lists.map((list) => (
          <ListPreview key={list.uuid} reloadLists={reloadLists} listData={list} />
        ))
      ) : (
        <p className='noLists'>No lists found</p>
      )}
    </div>
  )
}

export default ListTable
