import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import 'styles/components/generic/buttonWidget.scss'

function ButtonWidget({
  icon,
  title,
  description,
  route,
  digit,
}: {
  icon: ReactElement
  title: string
  description?: string
  route: string
  digit?: number
}) {
  return (
    <div className='buttonWidget'>
      <Link className='route' to={route}>
        {icon}
        <div className='textBox'>
          <h1 className='title'>{title}</h1>
          {description && <p className='description'>{description}</p>}
        </div>
        {digit && <span className='digit'>{digit}</span>}
      </Link>
    </div>
  )
}

export default ButtonWidget
