import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import 'styles/pages/appPage.scss'
import { appController } from 'controllers'
import { appInterface } from 'interfaces'
import { AppPlatforms } from 'components/apps'
import { IPlatform, IPlatformsMini } from 'interfaces/appInterface'

function AppPage() {
  const params = useParams()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const uuid: string = params.uuid || ''
  const [app, setApp] = useState<appInterface.IApp>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  async function getAppById() {
    let appSearch: appInterface.IApp
    try {
      appSearch = await appController.getAppById(uuid)
      setApp(appSearch)
      return setIsLoading(false)
    } catch (error: any) {
      return navigate('/home')
    }
  }

  async function changeAppStatus({ status }: { status: appInterface.Status }) {
    try {
      if (app) await appController.changeAppStatus({ uuid: app?.uuid, status })
      await getAppById()
      enqueueSnackbar('Status has been updated', { variant: 'success' })
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  async function deleteApp() {
    try {
      if (app) await appController.deleteApp(app?.uuid)
      enqueueSnackbar('App has been deleted', { variant: 'success' })
      return navigate('/')
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  function getParsedPlatforms(): IPlatform[] {
    if (!app?.platforms) return []
    const platformsParsed = Object.keys(app.platforms)
      .filter((key) => key !== 'requests')
      .map((platform) => app.platforms[platform as keyof IPlatformsMini] as IPlatform)
    return platformsParsed || []
  }

  useEffect(() => {
    getAppById()
  }, [])

  return isLoading || !app ? (
    <h1>Loading...</h1>
  ) : (
    <div className='appPage'>
      {app.status === appInterface.Status.Reported && (
        <span className='warning'>This app has been reported a lot</span>
      )}
      {app.status && <span className='status'>{app.status}</span>}
      <div>
        {app.status === appInterface.Status.Approved ? (
          <button
            type='button'
            onClick={() => changeAppStatus({ status: appInterface.Status.Banned })}
            className='cta'
          >
            Ban
          </button>
        ) : (
          <button
            type='button'
            onClick={() => changeAppStatus({ status: appInterface.Status.Approved })}
            className='cta'
          >
            Approve
          </button>
        )}
        <button type='button' onClick={() => deleteApp()} className='cta'>
          Delete
        </button>
      </div>
      <img className='appImage' src={app.imageUrl || app.imageUrl} alt={app.title} />
      <h1 className='title'>
        {app.title} - {app.sellerName}
      </h1>
      <AppPlatforms
        appId={app.uuid}
        refreshApp={getAppById}
        platformRequests={app.platforms.requests}
        platforms={getParsedPlatforms()}
      />
      <p className='description'>{app.description}</p>
      <p className='price'>
        {app.platforms?.itunes?.price && app.platforms?.itunes?.price > 0 ? (
          app.platforms.itunes?.price
        ) : (
          <span>Free</span>
        )}
      </p>
      <p className='random'>Categories: {app.categories}</p>
      <p className='random'>
        Rating: {app.rating} (from {app.ratingCount} users)
      </p>
      <p className='random'>Release Date: {app.releaseDate}</p>
      <p className='random'>App by: {app.sellerName}</p>
      <p className='random'>Version: {app.rating}</p>
      <Link to={`/app/${uuid}/edit`}>
        <button className='cta' type='button'>
          Edit
        </button>
      </Link>
    </div>
  )
}

export default AppPage
