import React, { useState, useEffect } from 'react'
import { BsPlusLg, BsXLg } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { AppList } from 'components/apps'
import { SavableSuggestions } from 'components/search'
import 'styles/components/list/listModal.scss'
import { InputField, CheckboxField, Modal } from 'components/generic'
import { appInterface, listInterface } from 'interfaces'
import { appController, listController } from 'controllers'
import { appHelper } from 'helpers'

interface ListModalProps {
  updateLists: () => void
  deleteList: () => void
  hideModal: () => void
  listData: listInterface.IList
  appsData?: appInterface.IApp[]
}

function ListModal({ updateLists, deleteList, hideModal, listData, appsData }: ListModalProps) {
  const navigate = useNavigate()
  const [list, setList] = useState<listInterface.IList>()
  const [apps, setApps] = useState<appInterface.IApp[]>()
  const [newListData, setNewListData] = useState<listInterface.INewList>({
    name: listData.name,
    description: listData.description,
    isPublic: listData.isPublic,
  })
  const [editMode, setEditMode] = useState<boolean>(false)
  const [showAppField, setShowAppField] = useState<boolean>(false)
  const [appSearch, setAppSearch] = useState<string>('')
  const [suggestions, setSuggestions] = useState<
    appInterface.IItunesParsedApp[] | appInterface.IApp[]
  >()
  const { enqueueSnackbar } = useSnackbar()

  async function getListById() {
    let listFetch: listInterface.IList
    let appsFetch: appInterface.IApp[]
    try {
      listFetch = await listController.getListById(listData.uuid)
      appsFetch = await appController.getManyAppsById(listFetch.apps)
      setApps(appsFetch)
      return setList(listFetch)
    } catch (error: any) {
      return navigate('/home')
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setNewListData({
      ...listData,
      [e.target.name]: e.target.value,
    })
  }

  async function saveApp(appData: appInterface.IItunesParsedApp) {
    try {
      setAppSearch('')
      setShowAppField(false)
      if (list) await listController.addAppToList({ uuid: list.uuid, appData })
      await getListById()
      await updateLists()
      return enqueueSnackbar('App Saved', { variant: 'success' })
    } catch (error: any) {
      return enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  async function deleteApp(appData: appInterface.IApp) {
    try {
      if (list) await listController.deleteAppFromList(list.uuid, appData.uuid)
      await getListById()
      await updateLists()
      return enqueueSnackbar(`${appData.title} has been deleted`, { variant: 'success' })
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }
  }

  async function updateList() {
    try {
      await listController.updateList({ uuid: listData.uuid, newListData })
      await updateLists()
      setEditMode(false)
      enqueueSnackbar('List Updated', { variant: 'success' })
      return
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    setApps(appsData)
    setList(listData)
  }, [appsData, listData])

  async function searchLocalApps() {
    let suggestionSearch: appInterface.IItunesParsedApp[] | appInterface.IApp[]
    let filteredSearch: appInterface.IItunesParsedApp[] | appInterface.IApp[]

    try {
      suggestionSearch = await appController.getMergedAppsByName(appSearch)
      if (appsData)
        filteredSearch = await appHelper.checkAddedApps(apps || appsData, suggestionSearch)
      else filteredSearch = suggestionSearch
      return setSuggestions(filteredSearch)
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    if (appSearch.length > 2) searchLocalApps()
    else setSuggestions(undefined)
  }, [appSearch])

  return list && apps ? (
    <Modal
      deleteModal={() => deleteList()}
      editModal={() => setEditMode(!editMode)}
      hideModal={() => hideModal()}
    >
      <span className='counter'>{apps.length} Apps</span>
      <div className='listModal'>
        {editMode ? (
          <div className='inputWrapper'>
            <InputField
              name='name'
              label='List Name'
              value={newListData?.name}
              handleChange={(e) => handleChange(e)}
            />
            <InputField
              name='description'
              label='Description'
              value={newListData?.description}
              handleChange={(e) => handleChange(e)}
            />
            <CheckboxField
              name='isPublic'
              label='Public'
              isChecked={newListData?.isPublic}
              handleChange={() =>
                setNewListData({ ...newListData, isPublic: !newListData?.isPublic })
              }
            />
            <button type='button' onClick={updateList} className='cta'>
              Save
            </button>
          </div>
        ) : (
          <div className='textWrapper'>
            <h1 className='title'>{list.name}</h1>
            <p className='description'>{list.description}</p>
          </div>
        )}
        <AppList apps={apps} deleteApp={(appData: appInterface.IApp) => deleteApp(appData)} />
        <div className='searchBox'>
          {showAppField && (
            <input
              className='searchField'
              value={appSearch}
              onChange={(v) => setAppSearch(v.target.value)}
            />
          )}
          {showAppField ? (
            <BsXLg
              className='addIcon close'
              onClick={() => {
                setAppSearch('')
                setShowAppField(false)
              }}
            />
          ) : (
            <BsPlusLg
              className='addIcon'
              onClick={() => {
                setShowAppField(true)
              }}
            />
          )}
        </div>
        {showAppField && (
          <p className='addNewApp'>
            Not what you are looking for?{' '}
            <Link className='link' to='/new-app'>
              Add a new app
            </Link>
          </p>
        )}
        <SavableSuggestions
          inList
          apps={suggestions}
          saveApp={(appData: appInterface.IItunesParsedApp) => saveApp(appData)}
        />
      </div>
    </Modal>
  ) : null
}

export default ListModal
