import React, { useEffect, useState } from 'react'
import 'styles/components/generic/customAttribute.scss'
import { BsPlusLg, BsXCircle } from 'react-icons/bs'
import { appInterface, attributeInterface } from 'interfaces'
import InputField from './inputField'
import { ICAttributes } from 'interfaces/appInterface'
import { attributeController } from 'controllers'
import SelectorField from './selectorField'

interface CustomAttributeProps {
  attributes?: appInterface.ICAttributes[]
  addNewAttribute: (attr: ICAttributes) => void
  removeAttribute: (attr: ICAttributes) => void
}

function CustomAttribute({ attributes, addNewAttribute, removeAttribute }: CustomAttributeProps) {
  const [isAdding, setIsAdding] = useState<boolean>(false)
  const [attributeOptions, setAttributeOptions] = useState<attributeInterface.IAttribute[]>()
  const [newAttribute, setNewAttribute] = useState<appInterface.ICAttributes>({
    name: '',
    value: '',
  })

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target
    setNewAttribute({
      ...newAttribute,
      [e.target.name]: value,
    })
  }

  function clear() {
    setNewAttribute({ name: '', value: '' })
    setIsAdding(false)
  }

  const getAttributes: () => Promise<void> = async () => {
    attributeController.getCustomAttributes().then((attr) => setAttributeOptions(attr))
  }

  useEffect(() => {
    getAttributes()
  }, [])

  return (
    <div className='customAttributesWrapper'>
      <span className='cATitle'>Custom Attributes</span>
      <div className='attributeWrapper'>
        {attributes?.map((attribute) => (
          <div key={attribute.value} className='attributeBox'>
            <span className='attributeName'>{attribute.name}</span>
            <span className='attributeValue'>{attribute.value}</span>
            <BsXCircle className='deleteAttribute' onClick={() => removeAttribute(attribute)} />
          </div>
        ))}
      </div>
      {isAdding ? (
        <div className='newAttributeBox'>
          <SelectorField
            name='Attribute'
            label='Type of Attribute'
            options={attributeOptions?.map((option) => ({
              value: option.name,
              label: option.name,
            }))}
            value={{
              value: newAttribute.name,
              label: newAttribute.name,
            }}
            handleChange={(attribute: { value: string; label: string }) =>
              setNewAttribute({ ...newAttribute, name: attribute.value })
            }
          />
          <InputField
            name='value'
            label='Value'
            value={newAttribute.value}
            handleChange={(e) => handleChange(e)}
          />
          <div className='buttonBox'>
            <button
              type='button'
              onClick={() => {
                addNewAttribute(newAttribute)
                clear()
              }}
              className='cta'
            >
              Add Attribute
            </button>
            <button type='button' onClick={clear} className='cta danger'>
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <BsPlusLg className='addIcon' onClick={() => setIsAdding(true)} />
      )}
    </div>
  )
}

export default CustomAttribute
