import React from 'react'
import { Link } from 'react-router-dom'
import 'styles/components/apps/appList.scss'
import { BsXLg } from 'react-icons/bs'
import { appInterface } from 'interfaces'

interface AppListProps {
  apps?: appInterface.IApp[]
  deleteApp?: (appData: appInterface.IApp) => void
  urlBuilder?: (uuid: string) => string
}

function AppList({ apps, deleteApp, urlBuilder }: AppListProps) {
  const buildUrl = urlBuilder || ((uuid) => `/app/${uuid}`)
  return (
    <div className='appList'>
      {apps &&
        apps.length > 0 &&
        apps.map((app) => (
          <div key={app.uuid}>
            <div className='appComponent'>
              <Link className='appUrl' to={buildUrl(app.uuid)}>
                <img className='appImage' src={app.imageUrl} alt={app.title} />
              </Link>
              <div className='textBox'>
                <Link className='appUrl' to={buildUrl(app.uuid)}>
                  <p className='title'>{app.title}</p>
                </Link>
                <p className='description'>{app.description}</p>
              </div>
              {deleteApp && (
                <BsXLg
                  onClick={() => {
                    deleteApp(app)
                  }}
                  className='appDeleteIcon'
                />
              )}
            </div>
          </div>
        ))}
    </div>
  )
}

export default AppList
