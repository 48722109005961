import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import 'styles/pages/logsPage.scss'
import { logController } from 'controllers'
import { logInterface } from 'interfaces'

function Logs({ isLoggedIn }: { isLoggedIn: boolean }) {
  const [logs, setLogs] = useState<logInterface.ILog[]>([])
  const [total, setTotal] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(0)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn, navigate])

  async function getAllLogs() {
    try {
      const logsFetch = await logController.getLogs(page, logInterface.LogType.appSearch)
      setLogs(logs.concat(logsFetch.items))
      setTotal(logsFetch.total)
      setPage(page + 1)
    } catch (error: any) {
      console.log(error)
    }
    return setIsLoading(false)
  }

  useEffect(() => {
    getAllLogs()
  }, [])

  return isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <div className='logsPage'>
      <h1 className='title'>App Logs</h1>
      <div>
        {logs.map((log) => (
          <p key={log.uuid}>
            user searched <span className='highlight'>{log.message}</span> but it wasn`t found
          </p>
        ))}
      </div>
      {logs.length < total && (
        <button type='button' onClick={() => getAllLogs()} className='cta'>
          Load More
        </button>
      )}
    </div>
  )
}

export default Logs
