import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import 'styles/pages/editAppPage.scss'
import { CustomAttribute, InputField, SelectorField } from 'components/generic'
import { appController, tagController } from 'controllers'
import { appInterface } from 'interfaces'
import { ISelectorState } from 'interfaces/selector'
import { appHelper } from 'helpers'
import { announcementOptions, categoryOptions } from 'utils/appConsts'
import Links from 'components/generic/links'
import { ILink } from 'interfaces/appInterface'

export default function EditAppPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [selectedCategories, setSelectedCategories] = useState<ISelectorState[]>()
  const [selectedTags, setSelectedTags] = useState<ISelectorState[]>()
  const [tagOptions, setTagOptions] = useState<ISelectorState[]>()
  const uuid: string | undefined = params.uuid
  const [newAppData, setNewAppData] = useState<appInterface.IUpdateApp>()
  const [existingApp, setExistingApp] = useState<appInterface.IUpdateApp>()
  const { enqueueSnackbar } = useSnackbar()
  const [isUpdating, setUpdating] = useState(false)
  const [customAttributes, setCustomAttributes] = useState<appInterface.ICAttributes[]>([])

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target
    setNewAppData({
      ...newAppData,
      [e.target.name]: value,
    })
  }

  function handleChangeAnnouncement(e: string) {
    setNewAppData({
      ...newAppData,
      announcement: {
        ...newAppData?.announcement,
        text: e,
      },
    })
  }

  async function getAppById() {
    try {
      if (!uuid) return navigate('/home')
      const appData: appInterface.IApp = await appController.getAppById(uuid)
      const { _id, ...fixedAppData } = appData
      setNewAppData({ ...fixedAppData })
      setExistingApp({ ...fixedAppData })
      setSelectedCategories(fixedAppData.categories.map((c) => ({ label: c, value: c })))
      setCustomAttributes(fixedAppData.customAttributes ?? [])
      setSelectedTags(fixedAppData.tags?.map((t) => ({ label: t, value: t })))
      setIsLoading(false)
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  async function fetchTags() {
    const tagOptionsFetch = await tagController.getTags()
    setTagOptions(tagOptionsFetch.map((tag) => ({ label: tag, value: tag })))
  }

  useEffect(() => {
    const fixTags = async () =>
      setNewAppData({
        ...newAppData,
        tags: (await appHelper.filterSelectorArray(selectedTags)) || [],
      })
    if (selectedTags) fixTags()
  }, [selectedTags])

  useEffect(() => {
    const fixCategories = async () =>
      setNewAppData({
        ...newAppData,
        categories: (await appHelper.filterSelectorArray(selectedCategories)) || [],
      })
    if (selectedCategories) fixCategories()
  }, [selectedCategories])

  async function updateAppById() {
    try {
      if (!uuid) return navigate('/home')
      setUpdating(true)
      if (newAppData) await appController.updateAppById(uuid, newAppData)
      enqueueSnackbar('App suggestion submitted successfully', {
        variant: 'success',
      })
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
    setUpdating(false)
  }

  function addCustomAttribute(attributeData: appInterface.ICAttributes) {
    setCustomAttributes([...customAttributes, attributeData])
  }

  function removeCustomAttribute(attributeData: appInterface.ICAttributes) {
    setCustomAttributes(customAttributes.filter((a) => a.name !== attributeData.name))
  }

  useEffect(() => {
    fetchTags()
    getAppById()
  }, [])

  useEffect(() => {
    setNewAppData((prev) => ({
      ...prev,
      customAttributes,
    }))
  }, [customAttributes])

  if (isLoading || !newAppData) return <h1>Loading...</h1>

  return (
    <section>
      <div className='editAppPage'>
        <img
          className='appImage'
          referrerPolicy='no-referrer'
          src={newAppData.imageUrl}
          alt={newAppData.title}
        />
        <div className='inputWrapper'>
          <h1 className='title'>Custom App</h1>
          <InputField
            name='title'
            label='App Title'
            value={newAppData?.title}
            handleChange={(e) => handleChange(e)}
          />
          <InputField
            name='description'
            label='Description'
            value={newAppData?.description}
            handleChange={(e) => handleChange(e)}
          />
          <SelectorField
            name='announcementType'
            label='Announcement Type'
            options={announcementOptions}
            value={{
              label: newAppData?.announcement?.type || '',
              value: newAppData?.announcement?.type || '',
            }}
            handleChange={(announcement: { value: string; label: string }) => {
              setNewAppData({
                ...newAppData,
                announcement: {
                  ...newAppData?.announcement,
                  type: announcement.value,
                },
              })
            }}
          />
          <InputField
            name='announcement'
            label='Announcement'
            value={newAppData?.announcement?.text}
            handleChange={(e) => {
              handleChangeAnnouncement(e.target.value)
            }}
          />

          <InputField
            name='sellerName'
            label='Vendor Name'
            value={newAppData?.sellerName}
            handleChange={(e) => handleChange(e)}
          />
          <SelectorField
            name='categories'
            isMulti
            label='Categories'
            options={categoryOptions}
            value={selectedCategories}
            handleChange={(categories: ISelectorState[]) => setSelectedCategories(categories)}
          />
          <SelectorField
            isMulti
            name='tags'
            label='Tags'
            value={selectedTags}
            options={tagOptions}
            creatable
            handleChange={(tags: ISelectorState[]) => setSelectedTags(tags)}
          />
          <CustomAttribute
            attributes={newAppData?.customAttributes}
            addNewAttribute={(attributeData: appInterface.ICAttributes) =>
              addCustomAttribute(attributeData)
            }
            removeAttribute={(attributeData: appInterface.ICAttributes) =>
              removeCustomAttribute(attributeData)
            }
          />
          <Links
            links={newAppData?.links}
            addNewLink={(linkData) =>
              setNewAppData({ ...newAppData, links: [...(newAppData?.links as ILink[]), linkData] })
            }
            removeLink={(linkData) =>
              setNewAppData({
                ...newAppData,
                links: newAppData.links?.filter((link) => link.type !== linkData.type),
              })
            }
          />
        </div>
        <button
          className='cta'
          disabled={isUpdating || JSON.stringify(newAppData) === JSON.stringify(existingApp)}
          onClick={updateAppById}
          type='button'
        >
          Save
        </button>
      </div>
    </section>
  )
}
