import React from 'react'
import 'styles/components/generic/inputField.scss'

function InputField({
  name,
  label,
  value,
  handleChange,
}: {
  name: string
  label?: string
  value?: string | number
  handleChange: React.ChangeEventHandler<HTMLInputElement>
}) {
  return (
    <div className='inputField'>
      {label && (
        <label htmlFor={name} className='label'>
          {label}
        </label>
      )}
      <input
        id={name}
        name={name}
        type='text'
        className='input'
        value={value}
        onChange={handleChange}
      />
    </div>
  )
}

export default InputField
