import React, { useState } from 'react'
import 'styles/components/attributes/newAttribute.scss'
import { BsPlusLg } from 'react-icons/bs'
import { useSnackbar } from 'notistack'
import { attributeController } from 'controllers'
import { InputField } from 'components/generic'
import { IAttribute } from 'interfaces/attributeInterface'

function NewAttribute({ addAttribute }: { addAttribute: (attr: IAttribute) => void }) {
  const [isAdding, setIsAdding] = useState<boolean>(false)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [attribute, setAttribute] = useState<string>()
  const { enqueueSnackbar } = useSnackbar()

  const addNewAttribute = async () => {
    try {
      setIsUpdating(true)
      if (attribute) {
        const newAttribute = await attributeController.addCustomAttribute(attribute)
        addAttribute(newAttribute)
        enqueueSnackbar('Attribute Added', { variant: 'success' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
    setAttribute('')
    setIsAdding(false)
    setIsUpdating(false)
  }

  return (
    <div className='newAttribute'>
      {isAdding ? (
        <div className='newAttributeBox'>
          <InputField
            name='attribute'
            label='Attribute'
            value={attribute}
            handleChange={(e) => setAttribute(e.target.value)}
          />
          <button className='cta' disabled={isUpdating} onClick={addNewAttribute} type='button'>
            Save
          </button>
        </div>
      ) : (
        <BsPlusLg
          onClick={() => {
            setIsAdding(true)
          }}
          className='attributeAddIcon'
        />
      )}
    </div>
  )
}

export default NewAttribute
