import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { appInterface } from 'interfaces'
import { InputField, SelectorField } from 'components/generic'
import 'styles/pages/newAppPage.scss'
import { appHelper } from 'helpers'
import { appController } from 'controllers'
import { categoryOptions, announcementOptions } from 'utils/appConsts'
import { ILink } from 'interfaces/appInterface'
import Links from 'components/generic/links'

function NewAppPage() {
  const navigate = useNavigate()
  const [appData, setAppData] = useState<appInterface.INewApp>()
  const [selectedCategories, setSelectedCategories] = useState<{ value: string; label: string }[]>()
  const [selectedLinks, setSelectedLinks] = useState<ILink[]>([])
  const [selectedTags, setSelectedTags] = useState<{ value: string; label: string }[]>()
  const [selectedAnnouncementType, setSelectedAnnouncementType] = useState<{
    value: string
    label: string
  }>()
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target
    setAppData({
      ...appData,
      [e.target.name]: value,
    })
  }

  function handleChangeAnnouncement(e: string) {
    setAppData({
      ...appData,
      announcement: {
        ...appData?.announcement,
        text: e,
      },
    })
  }

  useEffect(() => {
    const fixTags = async () => {
      const filterTags = await appHelper.filterSelectorArray(selectedTags)
      if (filterTags) setAppData({ ...appData, tags: filterTags })
    }

    if (selectedTags) fixTags()
  }, [selectedTags])

  async function createApp() {
    try {
      if (appData) await appController.createApp({ ...appData, links: selectedLinks })
      navigate('/')
      return
    } catch (error: any) {
      console.log('App creation failed', error)
    }
  }

  return (
    <div className='newAppPage'>
      <h1 className='title'>Custom App</h1>
      <div className='inputWrapper'>
        <InputField
          name='title'
          label='App Title'
          value={appData?.title}
          handleChange={(e) => handleChange(e)}
        />
        <InputField
          name='description'
          label='Description'
          value={appData?.description}
          handleChange={(e) => handleChange(e)}
        />
        <SelectorField
          name='announcementType'
          label='Announcement Type'
          options={announcementOptions}
          value={selectedAnnouncementType}
          handleChange={(announcement: { value: string; label: string }) => {
            setSelectedAnnouncementType(announcement)
            setAppData({
              ...appData,
              announcement: {
                ...appData?.announcement,
                type: announcement.value,
              },
            })
          }}
        />
        {selectedAnnouncementType?.value && (
          <InputField
            name='announcement'
            label='Announcement'
            value={appData?.announcement?.text}
            handleChange={(e) => {
              handleChangeAnnouncement(e.target.value)
            }}
          />
        )}
        <SelectorField
          name='categories'
          label='Categories'
          options={categoryOptions}
          value={selectedCategories}
          handleChange={(categories: Array<{ value: string; label: string }>) =>
            setSelectedCategories(categories)
          }
        />
        <SelectorField
          isMulti
          name='tags'
          label='Tags'
          options={categoryOptions}
          value={selectedTags}
          handleChange={(tags: Array<{ value: string; label: string }>) => setSelectedTags(tags)}
        />
        <InputField
          name='sellerName'
          label='Vendor Name'
          value={appData?.sellerName}
          handleChange={(e) => handleChange(e)}
        />
        <InputField
          name='imageUrl'
          label='Logo Link'
          value={appData?.imageUrl}
          handleChange={(e) => handleChange(e)}
        />
        <InputField
          name='price'
          label='Price'
          value={appData?.price}
          handleChange={(e) => handleChange(e)}
        />
        <InputField
          name='version'
          label='Version'
          value={appData?.version}
          handleChange={(e) => handleChange(e)}
        />
        <Links
          links={selectedLinks}
          addNewLink={(linkData) => setSelectedLinks([...selectedLinks, linkData])}
          removeLink={(linkData) =>
            setSelectedLinks([
              ...selectedLinks.filter((link: ILink) => link.type !== linkData.type),
            ])
          }
        />
      </div>
      <button type='button' onClick={createApp} className='cta'>
        Submit
      </button>
    </div>
  )
}

export default NewAppPage
