import android from './android.png'
import itunes from './itunes.png'
import linux from './linux.png'
import mac from './mac.png'
import windows from './windows.png'
import web from './web.png'

const icons = { android, itunes, linux, mac, windows, web }

export default icons
