import React from 'react'
import 'styles/components/search/suggestions.scss'
import { appInterface } from 'interfaces'
import { AppSearchSaveComponent, NoSearchResults } from '.'
import { IApp, IExternalParsedApp } from 'interfaces/appInterface'

function SavableSuggestions({
  apps,
  saveApp,
  inList,
}: {
  apps?: IExternalParsedApp[] | IApp[]
  saveApp: (app: IExternalParsedApp | IApp) => void
  inList?: boolean
}) {
  return (
    <div className={`suggestions ${apps && 'active'}`}>
      {apps && apps.length > 0 ? (
        apps.map((app) => (
          <AppSearchSaveComponent
            key={app.uuid}
            inList={inList}
            appData={app}
            saveApp={(app: IExternalParsedApp | IApp) => saveApp(app)}
          />
        ))
      ) : (
        <NoSearchResults />
      )}
    </div>
  )
}

export default SavableSuggestions
