export enum LogType {
  appSearch = 'appSearch',
}

export interface ILog {
  uuid: string
  userId: string
  timestamp: Date
  type: LogType
  message: string
}
