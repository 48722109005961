/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  BsList,
  BsFillPersonLinesFill,
  BsAppIndicator,
  BsExclamationSquare,
  BsBraces,
  BsWrench,
  BsBug,
} from 'react-icons/bs'
import { GiLog } from 'react-icons/gi'
import { GrSystem } from 'react-icons/gr'
import { FaMoneyBillWave } from 'react-icons/fa'
import { useSnackbar } from 'notistack'
import 'styles/pages/dashboardPage.scss'
import { ButtonWidget } from 'components/generic'
import { adminInterface } from 'interfaces'
import { adminController } from 'controllers'
import { MdFeaturedPlayList, MdOutlineFeaturedPlayList } from 'react-icons/md'

function DashboardPage({ isLoggedIn }: { isLoggedIn: boolean }) {
  const navigate = useNavigate()
  const [analytics, setAnalytics] = useState<adminInterface.IBasicAnalytics>()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn, navigate])

  async function getBasicAnalytics() {
    try {
      const analyticsFetch = await adminController.getBasicAnalytics()
      return setAnalytics(analyticsFetch)
    } catch (error: any) {
      return enqueueSnackbar(error.message, { variant: 'error' })
    }
  }
  useEffect(() => {
    getBasicAnalytics()
  }, [])

  return (
    <div className='dashboardPage'>
      <h1 className='title'>Dashboard</h1>
      <div className='widgets'>
        <ButtonWidget
          icon={<BsFillPersonLinesFill className='icon' />}
          title='Users'
          digit={analytics?.users}
          route='/users'
        />
        <ButtonWidget
          icon={<BsAppIndicator className='icon' />}
          title='All Apps'
          digit={analytics?.apps}
          route='/apps'
        />
        <ButtonWidget
          icon={<BsExclamationSquare className='icon' />}
          title='Approve Apps'
          digit={analytics?.pendingApps}
          route='/apps/pending'
        />
        <ButtonWidget
          icon={<BsList className='icon' />}
          title='Lists'
          digit={analytics?.lists}
          route='/lists'
        />
        <ButtonWidget
          icon={<BsWrench className='icon' />}
          title='Pending App Changes'
          digit={analytics?.pendingAppChanges}
          route='/apps/pending-changes'
        />
        <ButtonWidget
          icon={<BsBraces className='icon' />}
          title='Custom Attributes'
          digit={analytics?.customAttributes}
          route='/apps/attributes'
        />
        <ButtonWidget
          icon={<GrSystem className='icon' />}
          title='Approve Platforms'
          digit={analytics?.pendingPlatforms}
          route='/apps/pending-platforms'
        />
        <ButtonWidget
          icon={<BsBug className='icon' />}
          title='App Reports'
          digit={analytics?.appReports}
          route='/apps/app-reports'
        />
        <ButtonWidget
          icon={<GiLog className='icon' />}
          title='Logs'
          digit={analytics?.logs}
          route='/logs'
        />
        <ButtonWidget
          icon={<FaMoneyBillWave className='icon' />}
          title='Suggested Subscriptions'
          digit={analytics?.suggestedSubscriptions}
          route='/suggested-subscriptions'
        />
        <ButtonWidget
          icon={<MdOutlineFeaturedPlayList className='icon' />}
          title='Featured Lists'
          digit={analytics?.featuredLists}
          route='/featured-lists'
        />
      </div>
    </div>
  )
}

export default DashboardPage
