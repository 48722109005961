import { errorHelper } from 'helpers'
import { getAuthInstance } from 'utils/axiosInstance'
import { INewSubscription, ISubscription } from 'interfaces/subscriptionsInterface'

const backendURL = process.env.REACT_APP_BACKEND_URL
const subscriptionInstance = getAuthInstance(`${backendURL}/suggested-subscriptions`)

export async function createSubscription(subscription: INewSubscription) {
  try {
    await subscriptionInstance.post('/', { subscription })
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getSubscriptions() {
  try {
    const apps = await subscriptionInstance.get('/admin')
    return apps.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function deleteSubscription(subscriptionId: string) {
  try {
    await subscriptionInstance.delete('/', { data: { subscriptionId } })
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function updateSubscription(subscription: INewSubscription) {
  try {
    return await subscriptionInstance.put('/', { subscription })
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}
