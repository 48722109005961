import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineUser, AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'
import { FiLogOut } from 'react-icons/fi'
import { BsList } from 'react-icons/bs'
import logo from 'assets/images/logo.png'
import 'styles/components/generic/menu.scss'
import { adminController } from 'controllers'

interface MenuProps {
  isLoggedIn: boolean
  setIsLoggedIn: (value: boolean) => void
}

function Menu({ isLoggedIn, setIsLoggedIn }: MenuProps) {
  const navigate = useNavigate()

  async function goTo(path: string) {
    navigate(path)
  }

  async function logout() {
    await adminController.logout()
    setIsLoggedIn(false)
    navigate('/login')
  }

  return (
    <div className='menu'>
      <div className='logoBox'>
        <Link className='logo' to='/'>
          <img src={logo} alt='logo' className='image' />
        </Link>
      </div>
      <div className='widgetBox'>
        {isLoggedIn ? (
          <>
            <AiOutlinePlus onClick={() => goTo('/new-app')} className='widget' />
            <AiOutlineSearch onClick={() => goTo('/search')} className='widget' />
            <BsList onClick={() => goTo('/public-lists')} className='widget' />
            <AiOutlineUser onClick={() => goTo('/')} className='widget' />
            <FiLogOut onClick={() => logout()} className='widget' />
          </>
        ) : (
          <AiOutlineUser onClick={() => goTo('/login')} className='widget' />
        )}
      </div>
    </div>
  )
}

export default Menu
