import { appInterface, listInterface, pageInterface } from 'interfaces'
import { errorHelper } from 'helpers'
import { getAuthInstance, getInstance } from 'utils/axiosInstance'
import { IPage } from 'interfaces/pageInterface'

const backendURL = process.env.REACT_APP_BACKEND_URL
const instance = getInstance(`${backendURL}/lists`)
const authInstance = getAuthInstance(`${backendURL}/lists`)
const adminInstance = getAuthInstance(`${backendURL}/admin`)

export async function createList(listData: listInterface.INewList) {
  try {
    await authInstance.post('/', JSON.stringify({ listData }))
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getAllLists({
  page,
}: {
  page?: number
}): Promise<pageInterface.IPage<listInterface.IList>> {
  try {
    const lists = await authInstance.get('/all/paginated', { params: { page } })
    return lists.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function deleteList({ uuid }: { uuid: string }) {
  try {
    await adminInstance.delete('/lists', { data: JSON.stringify({ uuid }) })
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function updateList({
  uuid,
  newListData,
}: {
  uuid: string
  newListData: listInterface.INewList
}) {
  try {
    await adminInstance.put('/lists', JSON.stringify({ uuid, newListData }))
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function addAppToList({
  uuid,
  appData,
}: {
  uuid: string
  appData: appInterface.IApp | appInterface.IItunesParsedApp
}) {
  try {
    await adminInstance.post('/lists/app', JSON.stringify({ uuid, appData }))
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function deleteAppFromList(uuid: string, appId: string) {
  try {
    await adminInstance.put('/lists/app/delete', JSON.stringify({ uuid, appId }))
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getListsByUserId(userId: string): Promise<listInterface.IList[]> {
  try {
    const lists = await adminInstance.get('/lists/user', { params: { userId } })
    return lists.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getListsByNamePaginated(
  name: string,
  page?: number,
): Promise<IPage<listInterface.IList>> {
  try {
    const lists = await authInstance.get('/search/name/paginated', { params: { name, page } })
    return lists.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getListsByName(name: string): Promise<listInterface.IList[]> {
  try {
    const lists = await authInstance.get('/search/name', { params: { name } })
    return lists.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export const getFeaturedLists = async (): Promise<listInterface.IList[]> => {
  try {
    const lists = await instance.get('/featured')
    return lists.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export const addFeaturedList = async (uuid: string) => {
  try {
    await authInstance.post('/featured', JSON.stringify({ uuid }))
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export const deleteFeaturedList = async (uuid: string) => {
  try {
    await authInstance.delete('/featured', { data: JSON.stringify({ uuid }) })
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getPublicLists() {
  try {
    const lists = await instance.get('/public')
    return lists.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getListById(uuid: string) {
  try {
    const lists = await instance.get('/', { params: { uuid } })
    return lists.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getListsByIds({
  uuidList,
}: {
  uuidList: string[]
}): Promise<listInterface.IList[]> {
  try {
    const response = await authInstance.get('/id', { params: { uuidList } })
    return response.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}
