import React from 'react'
import 'styles/components/generic/checkboxField.scss'

function CheckboxField({
  name,
  label,
  isChecked,
  handleChange,
}: {
  name: string
  label?: string
  isChecked?: boolean
  handleChange: React.ChangeEventHandler<HTMLInputElement>
}) {
  return (
    <div className='checkboxField'>
      <input
        id={name}
        name={name}
        type='checkbox'
        className='checkbox'
        checked={isChecked}
        onChange={handleChange}
      />
      {label && (
        <label htmlFor={name} className='label'>
          {label}
        </label>
      )}
    </div>
  )
}

export default CheckboxField
