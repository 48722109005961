import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import 'styles/pages/pendingAppChangePage.scss'
import { appController } from 'controllers'
import { appInterface, appSuggestionInterface } from 'interfaces'
import { AppPlatforms } from 'components/apps'

function PendingAppChangePage({ isLoggedIn }: { isLoggedIn: boolean }) {
  const params = useParams()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const uuid: string = params.uuid || ''
  const [app, setApp] = useState<appInterface.IApp>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [suggestedApp, setSuggestedApp] = useState<appSuggestionInterface.IAppSuggestionExtended>()

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn, navigate])

  async function getAppById() {
    try {
      const appSearch = await appController.getSuggestedAppUpdateById(uuid)
      setApp(appSearch.app)
      setSuggestedApp(appSearch)
      return setIsLoading(false)
    } catch (error: any) {
      return navigate('/')
    }
  }

  async function deleteAppSuggestion() {
    try {
      await appController.deleteSuggestedAppUpdateById(uuid)
      navigate('/apps/pending-changes')
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  async function approveAppSuggestion() {
    try {
      await appController.approveSuggestedAppUpdateById(uuid)
      navigate('/apps/pending-changes')
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    getAppById()
  }, [])

  return isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <>
      <div className='pendingAppChangePage'>
        <div className='appContainer'>
          <h2>App</h2>
          {app?.imageUrl ? (
            <img className='appImage' src={app.imageUrl || app.imageUrl} alt={app?.title} />
          ) : null}
          <h1 className='title'>
            {app?.title && app.title}
            &nbsp;-&nbsp;
            {app?.sellerName && app.sellerName}
          </h1>
          {app && app.platforms?.requests && app.platforms.requests.length > 0 && (
            <AppPlatforms
              refreshApp={getAppById}
              appId={app.uuid}
              platforms={app.platforms.requests}
            />
          )}
          <p className='description'>
            Description:&nbsp;
            {app?.description ? app.description : 'missing'}
          </p>
          <p className='price'>
            Price:&nbsp;
            {app?.platforms?.itunes?.price && app?.platforms?.itunes?.price > 0 ? (
              app?.platforms.itunes?.price
            ) : (
              <span>Free</span>
            )}
          </p>
          <p className='random'>Categories: {app?.categories && app.categories.join(', ')}</p>
          <p className='random'>
            Release Date:&nbsp;
            {app?.releaseDate ? app.releaseDate : 'missing'}
          </p>
          <p className='random'>App by: {app?.sellerName ? app.sellerName : 'missing'}</p>
          <p className='random'>Tags: {app?.tags ? app.tags.join(', ') : 'missing'}</p>
          <p className='random'>Status: {app?.status ? app.status : 'missing'}</p>
          <div className='screenshots'>
            {app?.screenshots?.length
              ? app.screenshots.map((screenshotUrl, i) => (
                  <img key={screenshotUrl + i} src={screenshotUrl} alt={screenshotUrl} />
                ))
              : 'No screenshots'}
          </div>
          <p>
            Custom Attributes:{' '}
            {app?.customAttributes?.length ? app.customAttributes.join(', ') : 'missing'}
          </p>
          {/* <p className="random">Rating: { app?.rating ? app.rating : 'missing' }</p>
            <p className="random">Rating Count: { suggestedApp?.ratingCount ? suggestedApp.ratingCount : 'missing' }</p> */}
        </div>
        <div className='appChangeContainer'>
          <h2>Suggested Changes</h2>
          {suggestedApp?.imageUrl ? (
            <img className='appImage' src={suggestedApp.imageUrl} alt={suggestedApp?.title} />
          ) : (
            <img className='appImage' src={app?.imageUrl} alt={app?.title} />
          )}
          <h1 className='title'>
            {suggestedApp?.title ? suggestedApp.title : app?.title && app.title}
            &nbsp;-&nbsp;
            {suggestedApp?.sellerName && suggestedApp.sellerName}
          </h1>
          {suggestedApp &&
            app &&
            suggestedApp.platforms?.requests &&
            suggestedApp.platforms.requests.length > 0 && (
              <AppPlatforms
                refreshApp={getAppById}
                appId={app.uuid}
                platforms={suggestedApp.platforms.requests}
              />
            )}
          <p className='description'>
            Description: {suggestedApp?.description ? suggestedApp.description : 'missing'}
          </p>
          <p className='price'>
            Price:&nbsp;
            {app?.platforms?.itunes?.price && app?.platforms?.itunes?.price > 0 ? (
              app?.platforms.itunes?.price && app.platforms.itunes.price
            ) : (
              <span>Free</span>
            )}
          </p>
          <p className='random'>
            Categories: {suggestedApp?.categories ? suggestedApp.categories : 'missing'}
          </p>
          <p className='random'>
            Release Date: {suggestedApp?.releaseDate ? suggestedApp.releaseDate : 'missing'}
          </p>
          <p className='random'>
            App by: {suggestedApp?.sellerName ? suggestedApp.sellerName : 'missing'}
          </p>
          <p className='random'>
            Tags: {suggestedApp?.tags ? suggestedApp.tags.join(', ') : 'missing'}
          </p>
          <p className='random'>Status: {suggestedApp?.status ? suggestedApp.status : 'missing'}</p>
          <div className='screenshots'>
            {suggestedApp?.screenshots?.length
              ? suggestedApp.screenshots.map((screenshotUrl, i) => (
                  <img key={screenshotUrl + i} src={screenshotUrl} alt={screenshotUrl} />
                ))
              : 'No screenshots'}
          </div>
          <p>
            Custom Attributes:{' '}
            {suggestedApp?.customAttributes?.length
              ? suggestedApp.customAttributes.join(', ')
              : 'missing'}
          </p>
          {/* <p className="random">Rating: { suggestedApp?.rating ? suggestedApp.rating : 'missing' }</p>
            <p className="random">Rating Count: { suggestedApp?.ratingCount ? suggestedApp.ratingCount : 'missing' }</p> */}
        </div>
      </div>
      <div className='pendingAppChangePage__buttonContainer'>
        <button type='button' className='cta' onClick={approveAppSuggestion}>
          Approve
        </button>
        <button type='button' className='cta' onClick={deleteAppSuggestion}>
          Reject
        </button>
      </div>
    </>
  )
}

export default PendingAppChangePage
