import React from 'react'
import { BsPlusLg, BsCheckLg } from 'react-icons/bs'
import { appInterface } from 'interfaces'

interface AppSearchSaveComponentProps {
  appData: appInterface.IItunesParsedApp | appInterface.IApp
  saveApp: (appData: appInterface.IItunesParsedApp | appInterface.IApp) => void
  inList?: boolean
}

function AppSearchSaveComponent({ appData, saveApp, inList }: AppSearchSaveComponentProps) {
  return (
    <div
      className='appComponent'
      role='button'
      onKeyDown={() => {
        saveApp(appData)
      }}
      tabIndex={0}
      onClick={() => {
        if ((inList && !appData?.inList) || !appData?.added) saveApp(appData)
      }}
      key={appData.uuid}
    >
      <div className='dataBox'>
        <img className='appImage' height='35' src={appData.imageUrl} alt={appData.title} />
        <div className='textBox'>
          <p className='title'>{appData.title}</p>
        </div>
      </div>
      {(inList && appData.inList) || appData.added ? (
        <BsCheckLg className='saveIcon addedIcon' />
      ) : (
        <BsPlusLg className='saveIcon' />
      )}
    </div>
  )
}

export default AppSearchSaveComponent
