import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import 'styles/pages/allPendingAppChangePage.scss'
import { appController } from 'controllers'
import { appInterface } from 'interfaces'
import { AppList } from 'components/apps'

function AllPendingAppChangePage({ isLoggedIn }: { isLoggedIn: boolean }) {
  const [apps, setApps] = useState<appInterface.IApp[]>([])
  const [total, setTotal] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(1)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn, navigate])

  async function getAllApps() {
    try {
      const appsFetch = await appController.getSuggestedAppUpdates(page)
      setApps(apps.concat(appsFetch.items.map((s) => ({ ...s.app, uuid: s.uuid }))))
      setTotal(appsFetch.total)
      setPage(page + 1)
    } catch (error: any) {
      console.log(error)
    }
    return setIsLoading(false)
  }

  useEffect(() => {
    getAllApps()
  }, [])

  return isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <div className='allAppsPage'>
      <h1 className='title'>All Suggested App Changes</h1>
      <div className='appBox'>
        <AppList apps={apps} urlBuilder={(uuid) => `/apps/pending-changes/${uuid}`} />
      </div>
      {apps.length < total && (
        <button type='button' onClick={() => getAllApps()} className='cta'>
          Load More
        </button>
      )}
    </div>
  )
}

export default AllPendingAppChangePage
