import React from 'react'
import classNames from 'classnames'
import styles from './Button.module.scss'

interface ButtonProps {
  label?: string
  onClick: () => void
  disabled?: boolean
  className?: string
  children?: React.ReactNode
}

export const Button = ({ label, onClick, disabled, className, children }: ButtonProps) => {
  return (
    <button className={classNames(styles.button, className)} onClick={onClick} disabled={disabled}>
      {label || children}
    </button>
  )
}
