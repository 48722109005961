import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import 'styles/pages/allAppsPage.scss'
import { appController } from 'controllers'
import { appInterface } from 'interfaces'
import { AppList } from 'components/apps'
import { useSnackbar } from 'notistack'

function AllAppsPage({ isLoggedIn, pending }: { isLoggedIn: boolean; pending?: boolean }) {
  const [search, setSearch] = useState('')
  const [apps, setApps] = useState<appInterface.IApp[]>([])
  const [count, setCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(0)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn, navigate])

  async function getAllApps() {
    try {
      const appsFetch: { apps: appInterface.IApp[]; count: number } =
        await appController.getAllApps({
          page,
          filter: pending ? { status: appInterface.Status.Pending } : undefined,
        })
      setApps(apps.concat(appsFetch.apps))
      setCount(appsFetch.count)
      setPage(page + 1)
    } catch (error: any) {
      console.log(error)
    }
    return setIsLoading(false)
  }

  async function findApps() {
    let appsFetch: appInterface.IApp[]
    try {
      appsFetch = await appController.getManyAppsByName({
        name: search,
        filter: pending ? { status: appInterface.Status.Pending } : undefined,
      })
      return setApps(appsFetch)
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    if (search.length > 2) findApps()
    else if (search.length === 0) getAllApps()
  }, [search])

  useEffect(() => {
    getAllApps()
  }, [])

  return isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <div className='allAppsPage'>
      <h1 className='title'>All Apps</h1>
      <input className='searchField' value={search} onChange={(v) => setSearch(v.target.value)} />
      <div className='appBox'>
        <AppList apps={apps} />
      </div>
      {search.length === 0 && apps.length < count && (
        <button type='button' onClick={() => getAllApps()} className='cta'>
          Load More
        </button>
      )}
    </div>
  )
}

export default AllAppsPage
