import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { IUser } from 'interfaces/userInterface'
import DoneIcon from '@mui/icons-material/Done'
import { useNavigate } from 'react-router-dom'

interface UserTableProps {
  users: IUser[]
  miniList?: boolean
}

export default function UserTable({ users, miniList }: UserTableProps) {
  const navigate = useNavigate()
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
      <TableContainer style={{ width: miniList ? 900 : 1400 }} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>UUID</TableCell>
              <TableCell align='left'>Email</TableCell>
              <TableCell align='left'>Name</TableCell>
              <TableCell align='left'>Username</TableCell>
              {!miniList && (
                <>
                  <TableCell align='center'>Followed Lists</TableCell>
                  <TableCell align='center'>Owned Lists</TableCell>
                  <TableCell align='center'>Last Login</TableCell>
                  <TableCell align='center'>Email Verified</TableCell>
                  <TableCell align='center'>Waitlist</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow
                onClick={() => navigate(`/users/${user.uuid}`)}
                key={user.uuid}
                style={{ cursor: 'pointer' }}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align='left'>{user.uuid}</TableCell>
                <TableCell align='left'>{user.email}</TableCell>
                <TableCell align='left'>{user.name}</TableCell>
                <TableCell align='left'>{user.username}</TableCell>
                {!miniList && (
                  <>
                    <TableCell align='center'>{user.followedLists?.length}</TableCell>
                    <TableCell align='center'>{user.lists.length}</TableCell>
                    <TableCell align='center'>
                      {user.lastLogin ? new Date(user.lastLogin).toDateString() : '-'}
                    </TableCell>
                    <TableCell align='center'>
                      {user.config?.onboarding?.emailVerified ? <DoneIcon /> : null}
                    </TableCell>
                    <TableCell align='center'>
                      {user.config?.waitlist?.isOnWaitlist ? <DoneIcon /> : null}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
