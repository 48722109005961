import { pageInterface, logInterface } from 'interfaces'
import { errorHelper } from 'helpers'
import { getAuthInstance } from 'utils/axiosInstance'

const backendURL = process.env.REACT_APP_BACKEND_URL
const instanceAuth = getAuthInstance(`${backendURL}/logs`)

export async function getLogs(
  page?: number,
  type?: logInterface.LogType,
): Promise<pageInterface.IPage<logInterface.ILog>> {
  try {
    const { data } = await instanceAuth.get('/', { params: { page, type } })
    return data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}
