/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import 'styles/pages/publicLists.scss'
import { useSnackbar } from 'notistack'
import logo from 'assets/images/logo.svg'
import { listController } from 'controllers'
import { listInterface } from 'interfaces'
import { ListTable } from 'components/list'

function PublicLists() {
  const [lists, setLists] = useState<listInterface.IList[]>()
  const { enqueueSnackbar } = useSnackbar()

  async function getLists() {
    let listSearch: listInterface.IList[]
    try {
      listSearch = await listController.getPublicLists()
      return setLists(listSearch)
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    getLists()
  }, [])

  return (
    <div className='publicLists'>
      <img src={logo} className='AppLogo' alt='logo' />
      <h1 className='title'>Maya - All Public Lists</h1>
      <ListTable reloadLists={() => getLists()} lists={lists} />
    </div>
  )
}

export default PublicLists
