import React, { useState } from 'react'
import styles from './Subscription.module.scss'
import { IconButton, Tooltip } from '@mui/material'
import { subscriptionsController } from 'controllers'
import { useSnackbar } from 'notistack'
import { FiEdit } from 'react-icons/fi'
import { EditSubscription } from './EditSubscription'
import { ISubscription } from 'interfaces/subscriptionsInterface'

interface SubscriptionProps {
  subscription: ISubscription
  getSubscriptions: () => void
}

export const Subscription = ({ subscription, getSubscriptions }: SubscriptionProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [editMode, setEditMode] = useState(false)

  const deleteSubscription = async () => {
    try {
      await subscriptionsController.deleteSubscription(subscription.uuid)
      getSubscriptions()
      enqueueSnackbar('Subscription deleted', { variant: 'success' })
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  const saveSubscription = async (subscriptionData: ISubscription) => {
    try {
      await subscriptionsController.updateSubscription({
        ...subscriptionData,
        currency: subscriptionData.currency.value,
      })
      await getSubscriptions()
      setEditMode(false)
      enqueueSnackbar('Subscription updated', { variant: 'success' })
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  return (
    <div className={styles.subscription}>
      {editMode ? (
        <EditSubscription
          subscriptionToEdit={subscription}
          deleteSubscription={deleteSubscription}
          saveSubscription={saveSubscription}
          cancelEdit={() => setEditMode(false)}
        />
      ) : (
        <div className={styles.subscriptionWrap}>
          <div className={styles.block}>
            <img src={subscription?.app?.imageUrl} className={styles.appIcon} alt='App Icon' />
            <p>{subscription?.app?.title}</p>

            <Tooltip className={styles.deleteIconWrapper} title='Edit Subscription'>
              <IconButton onClick={() => setEditMode(true)}>
                <FiEdit className={styles.deleteIcon} />
              </IconButton>
            </Tooltip>
          </div>
          {subscription.cost && (
            <p className={styles.price}>
              {subscription.cost} {subscription.currency} / {subscription.interval}
            </p>
          )}
        </div>
      )}
      {subscription.note && !editMode && (
        <div>
          <hr />
          <p className={styles.note}>{subscription.note}</p>
        </div>
      )}
    </div>
  )
}
