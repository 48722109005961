export enum Status {
  Approved = 'Approved',
  Pending = 'Pending',
  Banned = 'Banned',
  Reported = 'Reported',
}

export enum Platform {
  itunes = 'itunes',
  android = 'android',
  windows = 'windows',
  mac = 'mac',
  linux = 'linux',
  web = 'web',
}

export interface IReport {
  userId: string
  message: string
  timeStamp: Date
}

export interface IPlatform {
  id?: string
  url?: string
  type?: Platform
  requestId?: string
  sellerUrl?: string
  sellerName?: string
  icon?: string
  rating?: number
  ratingCount?: number
  approved?: boolean
  price?: number
}

export interface IPlatforms {
  itunes?: IPlatform
  android?: IPlatform
  windows?: IPlatform
  mac?: IPlatform
  linux?: IPlatform
  web?: IPlatform
  requests?: IPlatform[]
}
export interface IPlatformsMini {
  itunes?: IPlatform
  android?: IPlatform
  windows?: IPlatform
  mac?: IPlatform
  linux?: IPlatform
  web?: IPlatform
}

interface IAnnouncement {
  type?: string
  text?: string
}

export interface IApp {
  _id?: string
  uuid: string
  platforms: IPlatforms
  title: string
  description: string
  categories: string[]
  inList?: boolean
  added?: boolean
  tags?: string[]
  status?: Status
  reports?: IReport[]
  imageUrl: string
  screenshots?: string[]
  rating?: number
  ratingCount?: number
  releaseDate?: string
  sellerName: string
  version?: string
  userId?: string
  timestamp?: Date
  customAttributes?: ICAttributes[]
  url?: string
}

export interface IItunesParsedApp {
  uuid: string
  platforms: IPlatforms
  title: string
  description: string
  inList?: boolean
  categories: string[]
  added?: boolean
  tags?: string[]
  status?: Status
  reports?: IReport[]
  imageUrl: string
  screenshots?: string[]
  rating?: number
  ratingCount?: number
  releaseDate?: string
  sellerName: string
  version?: string
}

export interface ILink {
  type: string
  link: string
}

export interface INewApp {
  title?: string
  description?: string
  categories?: string[]
  tags?: string[]
  status?: Status
  imageUrl?: string
  xlImageUrl?: string
  screenshots?: string[]
  releaseDate?: string
  sellerUrl?: string
  rating?: number
  ratingCount?: number
  sellerName?: string
  userId?: string
  price?: number
  version?: string
  announcement?: IAnnouncement
  links?: ILink[]
}

export interface IStoreApp {
  trackId: number
  trackName: string
  trackViewUrl: string
  description: string
  artworkUrl100: string
}

export interface ICAttributes {
  name: string
  value: string
}

export interface IUpdateApp {
  platforms?: IPlatforms
  imageUrl?: string
  title?: string
  description?: string
  categories?: string[]
  tags?: string[]
  url?: string
  status?: Status
  releaseDate?: string
  sellerName?: string
  userId?: string
  customAttributes?: ICAttributes[]
  announcement?: IAnnouncement
  links?: ILink[]
}

export interface ICustomAttribute {
  uuid: string
  name: string
}

export interface IExternalParsedApp {
  uuid: string
  platforms: IPlatforms
  title: string
  description: string
  inList?: boolean
  categories: string[]
  added?: boolean
  tags?: string[]
  status?: Status
  reports?: IReport[]
  imageUrl: string
  screenshots?: string[]
  rating?: number
  ratingCount?: number
  releaseDate?: string
  sellerName: string
  version?: string
}
