import React from 'react'
import 'styles/components/search/suggestions.scss'
import { NoSearchResults } from '.'
import { IList } from 'interfaces/listInterface'
import { ListSearchSaveComponent } from './ListSearchSaveComponent'

interface ListSearchProps {
  lists?: IList[]
  saveList: (listId: string) => void
}

export const ListSearch = ({ lists, saveList }: ListSearchProps) => {
  return (
    <div className={`suggestions ${lists?.length && 'active'}`}>
      {lists && lists.length > 0 ? (
        lists.map((list) => (
          <ListSearchSaveComponent key={list.uuid} listData={list} saveList={saveList} />
        ))
      ) : (
        <NoSearchResults />
      )}
    </div>
  )
}
