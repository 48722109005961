import { adminInterface } from 'interfaces'
import { errorHelper } from 'helpers'
import { getAuthInstance, getInstance } from 'utils/axiosInstance'

const backendURL = process.env.REACT_APP_BACKEND_URL
const instance = getInstance(`${backendURL}/admin`)
const authInstance = getAuthInstance(`${backendURL}/admin`)

export async function login(adminLoginData: adminInterface.IAdminLogin) {
  try {
    await instance.post('/login', JSON.stringify({ adminLoginData }))
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getAdminData() {
  try {
    const response = await authInstance.get('/')
    return response.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function register(adminData: adminInterface.INewAdmin) {
  try {
    await instance.post('/', JSON.stringify({ adminData }))
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getBasicAnalytics() {
  try {
    const response = await authInstance.get('/analytics/basic')
    return response.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function logout() {
  try {
    await instance.post('/logout')
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}
