import React from 'react'

function NotFoundPage() {
  return (
    <div className='notFound'>
      <h1 className='title'>Maya - 404 Page Not Found</h1>
    </div>
  )
}

export default NotFoundPage
