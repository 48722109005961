import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import 'styles/pages/AttributesPage.scss'
import { attributeController } from 'controllers'
import { attributeInterface } from 'interfaces'
import { AttributeList } from 'components/attributes'
import NewAttribute from 'components/attributes/newAttribute'

function Attributes({ isLoggedIn }: { isLoggedIn: boolean }) {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentAttributes, setCurrentAttributes] = useState<attributeInterface.IAttribute[]>([])
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn, navigate])

  async function getAllAttributes() {
    try {
      const attributesFetch = await attributeController.getCustomAttributes()
      setCurrentAttributes(attributesFetch)
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
    return setIsLoading(false)
  }

  async function deleteAttribute(uuid: string) {
    try {
      const attributesFetch = await attributeController.deleteAttribute(uuid)
      setCurrentAttributes(attributesFetch)
      enqueueSnackbar('Attribute Deleted', { variant: 'success' })
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
    return setIsLoading(false)
  }

  useEffect(() => {
    getAllAttributes()
  }, [])

  return isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <div className='attributes'>
      <h1 className='title'>Custom Attributes</h1>
      <div className='attributeBox'>
        <AttributeList
          deleteAttribute={(uuid: string) => deleteAttribute(uuid)}
          attributes={currentAttributes}
        />
        <NewAttribute
          addAttribute={(newAttribute: attributeInterface.IAttribute) =>
            setCurrentAttributes([...currentAttributes, newAttribute])
          }
        />
      </div>
    </div>
  )
}

export default Attributes
