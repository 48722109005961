import React from 'react'
import { css } from '@emotion/react'
import ClipLoader from 'react-spinners/ClipLoader'
import 'styles/components/generic/loadingSpinner.scss'

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`

function LoadingSpinner() {
  return (
    <div className='loadingSpinner'>
      <ClipLoader color='#61dafb' loading css={override} size={150} />
    </div>
  )
}

export default LoadingSpinner
