import React, { useState } from 'react'
import { Button, ButtonGroup, TextField } from '@material-ui/core'
import { InputField, SelectorField } from 'components/generic'
import { LabelValue } from 'interfaces/generic'
import { AppList } from 'components/apps'
import styles from './EditSubscription.module.scss'
import { currencyOptions } from 'utils/appConsts'
import {
  ISubscription,
  SubscriptionInterval,
  SubscriptionPlan,
} from 'interfaces/subscriptionsInterface'

interface AddSubscriptionProps {
  subscriptionToEdit: ISubscription
  cancelEdit: () => void
  deleteSubscription: () => void
  saveSubscription: (subscription: ISubscription) => void
}

const billingIntervals = {
  month: { value: 'month', label: 'Month' },
  year: { value: 'year', label: 'Year' },
}

export const EditSubscription = ({
  subscriptionToEdit,
  deleteSubscription,
  saveSubscription,
  cancelEdit,
}: AddSubscriptionProps) => {
  const [subscriptionData, setSubscriptionData] = useState<ISubscription>({
    ...subscriptionToEdit,
    appId: subscriptionToEdit.app.uuid,
    currency: {
      label: subscriptionToEdit.currency.label || 'USD',
      value: subscriptionToEdit.currency.value || 'USD',
    },
  })

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    setSubscriptionData((s) => ({ ...s, [e.target.name]: e.target.value }))
  }

  return (
    <div className={styles.editSubscription}>
      <AppList apps={[subscriptionToEdit.app]} />
      {subscriptionData.plan === SubscriptionPlan.Paid && (
        <>
          <div className={styles.priceBox}>
            <InputField
              name='cost'
              label='Cost'
              value={subscriptionData.cost}
              handleChange={handleChange}
            />
            <SelectorField
              name='currency'
              label='Currency'
              options={currencyOptions}
              value={subscriptionData.currency}
              handleChange={(e: LabelValue) => setSubscriptionData((s) => ({ ...s, currency: e }))}
            />
          </div>
          <div className={styles.priceBox}>
            <SelectorField
              name='billing-frequency'
              label='Billing Frequency'
              options={Object.values(billingIntervals)}
              value={billingIntervals[subscriptionData.interval]}
              handleChange={(e: LabelValue) =>
                setSubscriptionData({
                  ...subscriptionData,
                  interval: e.value as SubscriptionInterval,
                })
              }
            />
          </div>
        </>
      )}

      <TextField
        label='Subscription Note'
        placeholder='Enter a note (up to 100 characters)'
        variant='outlined'
        fullWidth
        value={subscriptionData.note}
        onChange={(e) => setSubscriptionData((s) => ({ ...s, note: e.target.value }))}
        inputProps={{
          maxLength: 100,
        }}
        InputLabelProps={{
          style: { color: 'white' },
        }}
        InputProps={{
          style: { color: 'white' },
        }}
        className={styles.noteField}
      />

      <ButtonGroup className={styles.buttonGroup}>
        <Button
          onClick={() => saveSubscription(subscriptionData)}
          variant='contained'
          color='primary'
        >
          Save
        </Button>
        <Button onClick={cancelEdit} variant='contained'>
          Cancel
        </Button>
        <Button onClick={deleteSubscription} variant='contained' color='secondary'>
          Delete
        </Button>
      </ButtonGroup>
    </div>
  )
}
