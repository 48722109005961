import React from 'react'
import { useNavigate } from 'react-router-dom'
import { appInterface } from 'interfaces'

function AppSearchComponent({
  appData,
  clickable,
}: {
  appData: appInterface.IItunesParsedApp | appInterface.IApp
  clickable: boolean
}) {
  const navigate = useNavigate()
  async function goToApp() {
    return navigate(`/app/${appData.uuid}`)
  }

  return (
    <div
      className='appComponent'
      role='button'
      tabIndex={0}
      onKeyDown={() => {
        if (clickable) goToApp()
      }}
      onClick={() => {
        if (clickable) goToApp()
      }}
      key={appData.uuid}
    >
      <div className='dataBox'>
        <img className='appImage' height='35' src={appData.imageUrl} alt={appData.title} />
        <div className='textBox'>
          <p className='title'>{appData.title}</p>
        </div>
      </div>
    </div>
  )
}

export default AppSearchComponent
