import { errorHelper } from 'helpers'
import { pageInterface, userInterface } from 'interfaces'
import { getAuthInstance } from 'utils/axiosInstance'

const backendURL = process.env.REACT_APP_BACKEND_URL
const authInstance = getAuthInstance(`${backendURL}/users`)
const adminInstance = getAuthInstance(`${backendURL}/admin`)

export async function getAllUsers(
  page?: number,
): Promise<pageInterface.IPage<userInterface.IUser>> {
  try {
    const response = await authInstance.get('/all/paginated', { params: { page } })
    return response.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getUsersByName({
  name,
  page,
}: {
  name: string
  page?: number
}): Promise<pageInterface.IPage<userInterface.IUser>> {
  try {
    const response = await authInstance.get('/name/paginated', { params: { name, page } })
    return response.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function activateUser({ uuid }: { uuid?: string }) {
  try {
    await adminInstance.put('/user/activate', JSON.stringify({ uuid }))
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getUsersByIds({
  uuidList,
}: {
  uuidList: string[]
}): Promise<userInterface.IUser[]> {
  try {
    const response = await authInstance.get('/id', { params: { uuidList } })
    return response.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}
