/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import 'styles/components/generic/selectorField.scss'
import Select, { components } from 'react-select'
import Creatable from 'react-select/creatable'
import { LabelValue } from 'interfaces/generic'

interface SelectorFieldProps {
  isMulti?: boolean
  name: string
  label?: string
  options?: any[]
  value?: LabelValue[] | LabelValue
  handleChange: any
  disabled?: boolean
  creatable?: boolean
}

function Input(props: any) {
  return <components.Input {...props} maxLength={32} />
}

function SelectorField({
  isMulti,
  name,
  label,
  options,
  value,
  handleChange,
  disabled,
  creatable,
}: SelectorFieldProps) {
  return (
    <div className='selectorField'>
      {label && (
        <label htmlFor={name} className='label'>
          {label}
        </label>
      )}
      {creatable ? (
        <Creatable
          name={name}
          value={value}
          isMulti={isMulti}
          onChange={handleChange}
          options={options}
          components={{ Input }}
          className='selector'
          classNamePrefix='selector-field'
          isDisabled={disabled}
        />
      ) : (
        <Select
          name={name}
          value={value}
          isMulti={isMulti}
          onChange={handleChange}
          options={options}
          components={{ Input }}
          className='selector'
          classNamePrefix='selector-field'
          isDisabled={disabled}
        />
      )}
    </div>
  )
}

export default SelectorField
