import axios from 'axios'
import Cookies from 'js-cookie'

const backendURL = process.env.REACT_APP_BACKEND_URL
const tokenInstance = axios.create({
  baseURL: `${backendURL}/admin`,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
})

export const getAuthInstance = (baseURL: string) => {
  const authInstance = axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
  })

  authInstance.interceptors.request.use(
    async (config) => {
      const token = Cookies.get('accessToken')
      if (!token) {
        const response = await tokenInstance.get('/token')
        if (config.headers) config.headers.Authorization = `Bearer ${response.data.accessToken}`
      }
      if (config.headers) config.headers.Authorization = `Bearer ${token}`
      return config
    },
    (error) => Promise.reject(error),
  )

  return authInstance
}

export const getInstance = (baseURL: string) =>
  axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
  })
